import React from 'react'
import styled from 'styled-components'
import YouTube from 'react-youtube';


let num = Math.floor(Math.random() * 42000);

let visList = [
  "https://www.youtube.com/embed/Tpba70L2BLo?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/ORsySi8VAD4?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/NM2wtte1JRE?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/eckD-kHGNnE?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/Z7o2KTxK6a4?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/XwkdmHt_Ez8?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/c9f9fi4WZSM?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/utnrmjc7iUI?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/L3V7LKYPIUQ?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/vvbN-cWe0A0?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/Z1AtiyMrVbE?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/6iJ2nD5RPfk?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/HeZ9Qu5PE0g?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/eFIEdWrS0KU?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/c4yTaP6roTw?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244",
  "https://www.youtube.com/embed/LN3rTedzNoQ?ecver=2&loop=1&autoplay=1&showinfo=0&mute=1&controls=0&vq=244"
]


function shuffle(array) {
    let counter = array.length;
    while (counter > 0) {
        let index = Math.floor(Math.random() * counter);
        counter--;
        let temp = array[counter];
        array[counter] = array[index];
        array[index] = temp;
    }

    return array;
}

var vis = shuffle(visList);

function printLine(array, num) {
  let result = array[num];
  return result;
}

const Container = styled.div`
  height: 100%;
  width:100%
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`

const Video = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  transform: scale(2);
`

const Texture = styled.iframe`
  display: block;
  z-index: 800;
  position: absolute;
  opacity: 1;
`

const Stream = styled.iframe`
  display: block;
  z-index: 1000;
  position: absolute;
  opacity: .92;
`


export default class Youtube extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = {
      classes: 'hidden',
    }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 1000)
  }

  playVideo () {

  }
  render () {
   const { classes } = this.state
    return (
       <Video className={classes}>
        <Container className="music">
          <Texture src={printLine(vis, 2)} width="100%" height="100%" frameBorder="0" autoplay='true' allowFullScreen></Texture>
        </Container>
      </Video>

    )
  }
}
