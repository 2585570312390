import React from 'react'
import styled from 'styled-components'
import Social from '../../components/Social'


const Line = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items : center;
  top: 5px;
  width: 90%;
  box-sizing: border-box;
  margin: 20px auto 20px;
  padding: 20px 0 20px 0;
  border-bottom: .5px solid #fff;
  z-index: 5000;
  a, svg {
    height: 26.666px;
  }
  a {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  svg {
    fill: white;
    transition: all .3s ease;
    opacity: 0.666;
    &:hover {
      fill: blue;
      opacity: 1;
    }
  }
`

export default class Youtube extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 1000)
  }

  playVideo () {

  }
  render () {
   const { classes } = this.state
    return (
      <Line className={classes}>
        <Social />
      </Line>

    )
  }
}
