import React from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'

const DragMe = styled.img`
max-width: 66.6px;
max-height: 50.6px;

  @media only screen and (max-width: 678px) {
    max-width: 33.3px;
  }
`

export default class DraggableComp extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  render () {
    return (
      <Draggable position={null}>
        <div>
          <DragMe src={this.props.src} draggable='false' />
        </div>
      </Draggable>
    )
  }
}
