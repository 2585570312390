import React from 'react'
import styled from 'styled-components'
import OkLogo from '../../ok_logo.gif'

const RotatingLogoSmall = styled.img`
  height: 36.6px;
  mix-blend-mode: multiply;
  position: absolute;
  left: 4%;
  top: 25px;
  z-index: 1500;
`

export default class RotatingLogoSmallComp extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 10000);
  }
  render () {
   const { classes } = this.state
    return (

       <RotatingLogoSmall className={classes} src={OkLogo} />
    )
  }
}
