import React from 'react'
import styled from 'styled-components'
import ReactTwitchEmbedVideo from 'react-twitch-embed-video'

const CenterStream = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  padding-top: 3.33vh;
  opacity: 0.666666;
`

export default class Twitch extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 3000)
  }

  playVideo () {

  }
  render () {
   const { classes } = this.state
    return (
      <CenterStream className={classes}>
        <ReactTwitchEmbedVideo channel='whoisourkid' theme='dark' />
      </CenterStream>
    )
  }
}
