import React from 'react'
import styled from 'styled-components'
import DraggableComp from '../../components/Draggable'

import Algeria from './../../sponsors/ALGERIA_FLAG.png'
import Gazprom from './../../sponsors/GAZPROM_LOGO.png'
import Yakult from './../../sponsors/YAKULT_LOGO.png'
import Coop from './../../sponsors/CO_OP_LOGO.png'
import Nang from './../../sponsors/NANG.png'
import Ucl from './../../sponsors/UCL_LOGO.png'
import Montana from './../../sponsors/MONTANA_LOGO.png'
import Rekordbox from './../../sponsors/REKORDBOX_LOGO.png'
import Nescafe from './../../sponsors/NESCAFE_LOGO.png'
import Mcdonalds from './../../sponsors/MCDONALDS_LOGO.png'
import Apex from './../../sponsors/APEX_LOGO.png'
import Bolton from './../../sponsors/BOLTON_LOGO.png'
import Clipper from './../../sponsors/CLIPPER_LOGO.png'
import Universal from './../../sponsors/UNIVERSAL_LOGO.png'
import Soulseek from './../../sponsors/SOULSEEK_LOGO.png'
import Trackmania from './../../sponsors/TRACKMANIA_LOGO.png'
import Sixteen from './../../sponsors/HOURS_LOGO.png'

const Logos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px auto;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 60%;
  & div {
    margin: 0 5px;
  }
  z-index: 999999;
  @media only screen and (max-width: 768px) {
    width: 66.6%;
    margin-left: auto;
    margin-right: auto;
    .react-draggable {
      margin: 15px;
    }
  }
`

const SixteenComp = styled.div`
  position: relative;
  margin: 0 auto;
  z-index: 2000;
  text-align: center;
`

export default class LogosComp extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 3000);
  }
  render () {
   const { classes } = this.state;
    return (
      <div className={classes}>
        <SixteenComp>
          <DraggableComp src={Sixteen} />
        </SixteenComp>
        <Logos>
          <DraggableComp src={Gazprom} />
          <DraggableComp src={Algeria} />
          <DraggableComp src={Nang} />
          <DraggableComp src={Coop} />
          <DraggableComp src={Ucl} />
          <DraggableComp src={Montana} />
          <DraggableComp src={Yakult} />
          <DraggableComp src={Rekordbox} />
      </Logos>
      <Logos>
        <DraggableComp src={Nescafe} />
          <DraggableComp src={Mcdonalds} />
          <DraggableComp src={Apex} />
          <DraggableComp src={Bolton} />
          <DraggableComp src={Clipper} />
          <DraggableComp src={Universal} />
          <DraggableComp src={Soulseek} />
          <DraggableComp src={Trackmania} />

        </Logos>
     </div>
    )
  }
}
