import React from 'react'
import styled from 'styled-components'


const SpinningWrapper = styled.div`

`

const Spinning = styled.img`
  margin: 0 auto;
  mix-blend-mode: multiply;
  position: absolute;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1900;
`


const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  z-index: 99999999;
  a {
    margin-right: 15px;
    display: block;
    svg {
      height: 36.66px !important;
      width: auto;
      fill: white;
      stroke-color: white;
      &:hover {

      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export default class Social extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 5000);
  }
  render () {
   const { classes } = this.state
    return (
      <Socials class={classes}>
        <a href='https://www.soundcloud.com/djourkid'>

        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
        	 viewBox="0 0 35 35" >
        <g>
        	<path d="M28.6,15.35c0.63-0.26,1.26-0.35,1.92-0.3c0.72,0.06,1.39,0.29,1.99,0.69c0.51,0.34,0.92,0.78,1.24,1.29
        		c0.29,0.47,0.48,0.98,0.56,1.53c0.05,0.38,0.09,0.76,0.01,1.14c-0.05,0.23-0.07,0.47-0.13,0.69c-0.17,0.58-0.45,1.1-0.85,1.55
        		c-0.28,0.32-0.6,0.61-0.96,0.82c-0.25,0.14-0.52,0.27-0.78,0.37c-0.58,0.23-1.18,0.26-1.8,0.26c-3.68-0.01-7.37-0.01-11.05-0.01
        		c-0.36,0-0.58-0.21-0.58-0.57c0-2.39,0-4.77,0-7.16c0-1.93,0-3.87,0-5.8c0-0.51,0.06-0.58,0.55-0.76c1-0.37,2.05-0.5,3.11-0.42
        		c0.45,0.04,0.91,0.12,1.35,0.23c0.64,0.16,1.24,0.42,1.81,0.76c0.74,0.44,1.38,0.99,1.93,1.64c0.36,0.43,0.67,0.91,0.92,1.41
        		c0.22,0.44,0.41,0.9,0.52,1.38c0.09,0.39,0.14,0.8,0.21,1.2C28.58,15.31,28.59,15.32,28.6,15.35z"/>
        	<path d="M17.85,11.31c0,0.32-0.01,0.65,0,0.97c0.02,0.84,0.04,1.69,0.06,2.53c0.01,0.4,0.02,0.8,0.02,1.2
        		c0.02,1.09,0.06,2.18,0.06,3.27c0,0.5-0.04,1-0.07,1.49c-0.02,0.35-0.03,0.7-0.05,1.05c-0.02,0.38-0.04,0.76-0.08,1.14
        		c-0.02,0.26-0.23,0.4-0.49,0.38c-0.26-0.02-0.42-0.2-0.43-0.45c-0.03-0.53-0.06-1.05-0.09-1.58c-0.02-0.34-0.04-0.68-0.05-1.02
        		c-0.01-0.68-0.03-1.36-0.02-2.03c0.02-1.28,0.05-2.56,0.07-3.83c0.02-0.79,0.03-1.58,0.05-2.37c0.01-0.66,0.03-1.31,0.04-1.97
        		c0.01-0.24,0.21-0.45,0.46-0.45c0.25,0,0.45,0.16,0.46,0.42C17.82,10.47,17.83,10.89,17.85,11.31
        		C17.84,11.31,17.85,11.31,17.85,11.31z"/>
        	<path d="M16.45,13.95c0,0.57-0.01,1.14,0,1.71c0.02,1.19,0.06,2.39,0.07,3.58c0,0.59-0.04,1.19-0.07,1.78
        		c-0.02,0.39-0.04,0.79-0.06,1.18c-0.01,0.23-0.02,0.46-0.03,0.69c-0.02,0.39-0.36,0.6-0.68,0.42c-0.14-0.08-0.21-0.2-0.22-0.36
        		c-0.05-0.96-0.11-1.91-0.12-2.87c-0.02-1.04,0-2.08,0.01-3.12c0.01-0.78,0.02-1.56,0.04-2.34c0.02-1,0.04-2.01,0.06-3.01
        		c0-0.25,0-0.49,0.02-0.74c0.02-0.4,0.38-0.53,0.64-0.4c0.14,0.07,0.22,0.18,0.23,0.34c0.01,0.24,0.02,0.47,0.02,0.71
        		c0.02,0.8,0.03,1.61,0.05,2.41C16.43,13.95,16.44,13.95,16.45,13.95z"/>
        	<path d="M14.01,14.95c0.02-0.69,0.03-1.39,0.05-2.08c0-0.2,0.01-0.39,0.02-0.59c0.01-0.23,0.13-0.38,0.33-0.43
        		c0.19-0.04,0.37,0.05,0.46,0.25c0.02,0.04,0.03,0.08,0.03,0.12c0.02,0.56,0.04,1.11,0.05,1.67c0.02,0.77,0.04,1.55,0.06,2.32
        		c0.02,0.61,0.03,1.22,0.05,1.83c0.01,0.5,0.05,1,0.04,1.5c-0.02,0.66-0.07,1.33-0.11,1.99c-0.03,0.48-0.06,0.96-0.08,1.44
        		c-0.01,0.23-0.18,0.4-0.4,0.4c-0.23,0-0.41-0.17-0.42-0.4c-0.02-0.59-0.05-1.17-0.08-1.76c-0.02-0.33-0.04-0.66-0.05-0.99
        		c-0.02-0.31-0.04-0.61-0.04-0.92c0-0.42,0.01-0.83,0.02-1.25c0.02-0.66,0.04-1.31,0.05-1.97c0.01-0.38,0-0.76,0-1.13
        		C13.99,14.95,14,14.95,14.01,14.95z"/>
        	<path d="M12.52,18.84c0-0.1,0-0.19,0-0.29c0.02-0.83,0.04-1.66,0.06-2.5c0.01-0.59,0.03-1.18,0.04-1.77
        		c0.02-0.74,0.04-1.47,0.06-2.21c0-0.08,0.01-0.17,0.03-0.24c0.06-0.18,0.23-0.27,0.42-0.24c0.18,0.03,0.3,0.18,0.31,0.36
        		c0.02,0.62,0.03,1.25,0.05,1.87c0.03,1.14,0.07,2.29,0.1,3.43c0.02,0.58,0.01,1.17,0.06,1.75c0.05,0.65-0.04,1.29-0.07,1.93
        		c-0.03,0.63-0.07,1.25-0.11,1.88c-0.01,0.11-0.02,0.23-0.06,0.33c-0.07,0.19-0.21,0.23-0.38,0.22c-0.18-0.01-0.31-0.13-0.34-0.3
        		c-0.03-0.24-0.04-0.49-0.05-0.73c-0.04-0.73-0.09-1.45-0.12-2.18C12.51,19.72,12.53,19.28,12.52,18.84
        		C12.53,18.84,12.53,18.84,12.52,18.84z"/>
        	<path d="M12.15,16.03c0.02,0.88,0.05,1.76,0.07,2.65c0.01,0.25,0.03,0.49,0.02,0.74c-0.03,0.62-0.08,1.24-0.12,1.85
        		c-0.02,0.32-0.03,0.64-0.05,0.96c-0.02,0.27-0.03,0.54-0.05,0.81c-0.02,0.19-0.14,0.31-0.31,0.33c-0.15,0.02-0.3-0.05-0.35-0.2
        		c-0.03-0.09-0.04-0.18-0.05-0.28c-0.03-0.47-0.05-0.94-0.08-1.41c-0.03-0.46-0.07-0.91-0.09-1.37c-0.02-0.36-0.01-0.72-0.01-1.08
        		c0-0.51,0.01-1.02,0.03-1.52c0.03-1.07,0.07-2.13,0.1-3.2c0.02-0.54,0.02-1.09,0.04-1.64c0.01-0.3,0.02-0.61,0.03-0.91
        		c0.01-0.2,0.13-0.32,0.32-0.34c0.16-0.02,0.31,0.09,0.36,0.26c0.01,0.02,0.01,0.05,0.01,0.07c0.01,0.32,0.02,0.65,0.03,0.97
        		C12.08,13.83,12.11,14.93,12.15,16.03C12.15,16.03,12.15,16.03,12.15,16.03z"/>
        	<path d="M10.79,17.65c0.02,0.53,0.06,1.06,0.06,1.58c-0.01,0.62-0.07,1.23-0.11,1.84c-0.03,0.41-0.06,0.81-0.09,1.22
        		c-0.02,0.25-0.03,0.5-0.05,0.75c-0.02,0.23-0.17,0.32-0.33,0.32c-0.19,0-0.31-0.13-0.32-0.34c-0.03-0.54-0.07-1.08-0.1-1.62
        		c-0.04-0.79-0.1-1.58-0.12-2.36c-0.01-0.6,0.03-1.21,0.05-1.81c0.02-0.52,0.03-1.03,0.05-1.55c0.03-0.8,0.05-1.61,0.08-2.41
        		c0.01-0.42,0.03-0.84,0.04-1.26c0.01-0.16,0.12-0.29,0.28-0.31c0.15-0.02,0.3,0.08,0.34,0.23c0.01,0.04,0.02,0.09,0.02,0.14
        		c0.02,0.59,0.03,1.19,0.05,1.78c0.02,0.53,0.03,1.07,0.05,1.6c0.01,0.42,0.03,0.85,0.05,1.27C10.76,17.03,10.78,17.34,10.79,17.65
        		C10.79,17.65,10.79,17.65,10.79,17.65z"/>
        	<path d="M9.19,13.07c0.03,0.76,0.07,1.53,0.1,2.29c0.01,0.22,0.02,0.44,0.03,0.66c0.03,0.75,0.05,1.5,0.08,2.24
        		c0.01,0.4,0.05,0.81,0.04,1.21c-0.01,0.49-0.07,0.99-0.1,1.48c-0.02,0.3-0.04,0.61-0.07,0.91c-0.03,0.43-0.06,0.85-0.1,1.28
        		c-0.01,0.13-0.15,0.23-0.3,0.23c-0.14,0-0.26-0.1-0.28-0.24c-0.03-0.29-0.05-0.57-0.07-0.86c-0.03-0.41-0.05-0.82-0.08-1.23
        		c-0.03-0.47-0.09-0.94-0.09-1.4c0-0.68,0.03-1.36,0.05-2.03c0.02-0.65,0.05-1.31,0.07-1.96c0.02-0.48,0.03-0.96,0.05-1.45
        		c0.02-0.41,0.03-0.81,0.05-1.22c0.01-0.15,0.01-0.29,0.03-0.43c0.01-0.13,0.15-0.23,0.3-0.23c0.13,0,0.26,0.12,0.27,0.24
        		C9.19,12.73,9.19,12.9,9.19,13.07C9.2,13.07,9.2,13.07,9.19,13.07z"/>
        	<path d="M8.08,19.44c-0.03,0.37-0.07,0.75-0.1,1.14c-0.02,0.23-0.04,0.46-0.06,0.7c-0.03,0.37-0.06,0.75-0.09,1.12
        		c-0.02,0.24-0.04,0.47-0.06,0.71c-0.01,0.16-0.12,0.26-0.27,0.27c-0.13,0-0.25-0.1-0.26-0.25c-0.04-0.44-0.07-0.88-0.1-1.32
        		c-0.04-0.5-0.08-0.99-0.12-1.49c-0.04-0.52-0.07-1.04-0.01-1.57c0.02-0.16,0.01-0.32,0.02-0.47c0.05-0.97,0.09-1.94,0.14-2.92
        		c0.02-0.48,0.04-0.95,0.06-1.43c0.01-0.18,0.13-0.3,0.27-0.29c0.16,0,0.25,0.11,0.26,0.3c0.02,0.52,0.05,1.03,0.08,1.55
        		c0.02,0.47,0.05,0.94,0.08,1.41c0.03,0.6,0.06,1.2,0.1,1.81C8.04,18.93,8.06,19.17,8.08,19.44z"/>
        	<path d="M6.7,19.47c-0.04,0.45-0.08,0.9-0.12,1.34c-0.04,0.42-0.08,0.84-0.11,1.26c-0.03,0.35-0.06,0.7-0.1,1.04
        		c-0.01,0.14-0.11,0.22-0.22,0.22c-0.13,0-0.22-0.07-0.23-0.22c-0.03-0.26-0.05-0.51-0.07-0.77c-0.04-0.49-0.08-0.99-0.12-1.48
        		c-0.03-0.38-0.05-0.76-0.08-1.14c-0.03-0.42,0.03-0.84,0.07-1.25c0.02-0.26,0.04-0.53,0.06-0.8c0.04-0.47,0.08-0.93,0.12-1.4
        		C5.9,16.18,5.91,16.09,5.92,16c0.02-0.12,0.12-0.2,0.23-0.2c0.1,0,0.2,0.09,0.22,0.2c0.03,0.23,0.05,0.46,0.07,0.69
        		c0.05,0.51,0.09,1.02,0.14,1.54C6.63,18.65,6.67,19.06,6.7,19.47z"/>
        	<path d="M5.34,19.49c-0.03,0.34-0.06,0.72-0.1,1.09C5.2,20.99,5.16,21.4,5.12,21.8C5.08,22.24,5.04,22.67,5,23.1
        		c-0.01,0.11-0.09,0.17-0.2,0.17c-0.12,0-0.2-0.06-0.22-0.18c-0.02-0.1-0.02-0.21-0.03-0.31c-0.04-0.56-0.09-1.13-0.13-1.69
        		c-0.04-0.47-0.09-0.93-0.12-1.4c-0.01-0.27,0.01-0.54,0.03-0.81c0.01-0.24,0.04-0.48,0.06-0.72c0.04-0.48,0.08-0.97,0.12-1.45
        		c0.02-0.29,0.04-0.59,0.06-0.88c0.01-0.15,0.09-0.25,0.21-0.25c0.11,0,0.2,0.07,0.22,0.18c0.04,0.35,0.07,0.69,0.1,1.04
        		c0.04,0.41,0.07,0.82,0.11,1.23C5.24,18.34,5.27,18.67,5.3,19C5.31,19.15,5.32,19.3,5.34,19.49z"/>
        	<path d="M13.87,26.76c0-0.67,0-1.31,0-1.94c0.21,0,0.41,0,0.61,0c0,1.04,0,2.08,0,3.13c-0.18,0-0.35,0-0.53,0
        		c-0.03,0-0.07-0.04-0.09-0.07c-0.32-0.47-0.63-0.94-0.95-1.41c-0.11-0.16-0.21-0.32-0.33-0.5c0,0.2,0,0.37,0,0.54
        		c0,0.44,0,0.87,0.01,1.31c0,0.1-0.02,0.14-0.13,0.14c-0.14-0.01-0.27-0.01-0.41,0c-0.08,0-0.1-0.03-0.1-0.1c0-0.97,0-1.95,0-2.92
        		c0-0.08,0.02-0.11,0.11-0.1c0.15,0.01,0.31-0.01,0.46,0c0.04,0,0.09,0.06,0.12,0.1c0.29,0.43,0.58,0.87,0.87,1.3
        		C13.63,26.39,13.74,26.56,13.87,26.76z"/>
        	<path d="M3.99,19.41c-0.01,0.12-0.01,0.26-0.03,0.4c-0.04,0.45-0.09,0.9-0.14,1.35c-0.03,0.26-0.07,0.53-0.09,0.79
        		c-0.03,0.3-0.05,0.6-0.08,0.89c-0.01,0.06-0.01,0.12-0.03,0.17c-0.03,0.08-0.09,0.14-0.18,0.12c-0.09-0.02-0.15-0.07-0.16-0.18
        		c-0.05-0.51-0.1-1.02-0.15-1.54c-0.06-0.64-0.12-1.27-0.17-1.91C2.95,19.34,2.98,19.16,3,18.99c0.03-0.39,0.07-0.77,0.1-1.16
        		c0.04-0.48,0.08-0.95,0.12-1.43c0.02-0.18,0.03-0.36,0.05-0.53c0.01-0.1,0.07-0.17,0.17-0.18c0.11,0,0.17,0.06,0.18,0.16
        		c0.03,0.22,0.05,0.44,0.07,0.66c0.05,0.46,0.09,0.92,0.14,1.37c0.03,0.32,0.06,0.64,0.09,0.97C3.94,19.04,3.96,19.21,3.99,19.41z"
        		/>
        	<path d="M15.29,24.81c0.53,0.04,1.06-0.07,1.58,0.08c0.45,0.13,0.74,0.41,0.87,0.87c0.11,0.42,0.13,0.83-0.02,1.24
        		c-0.19,0.54-0.61,0.84-1.16,0.91c-0.41,0.05-0.84,0.03-1.27,0.05C15.29,26.91,15.29,25.87,15.29,24.81z M15.93,25.44
        		c0,0.63,0,1.25,0,1.88c0.12,0,0.23,0,0.35,0c0.07,0,0.14-0.01,0.21-0.03c0.32-0.08,0.57-0.25,0.63-0.65c0.03-0.2,0.03-0.41,0-0.6
        		c-0.04-0.22-0.16-0.4-0.38-0.5C16.48,25.42,16.21,25.45,15.93,25.44z"/>
        	<path d="M30.18,27.96c0-1.06,0-2.1,0-3.12c0.45,0,0.9-0.03,1.34,0.01c0.64,0.06,1.06,0.48,1.16,1.13c0.07,0.47,0.05,0.93-0.23,1.34
        		c-0.24,0.36-0.59,0.55-1.01,0.6C31.03,27.96,30.61,27.95,30.18,27.96z M30.81,25.43c0,0.19,0,0.34,0,0.49c0,0.41,0,0.82-0.01,1.24
        		c0,0.12,0.03,0.18,0.16,0.16c0.13-0.02,0.26-0.01,0.38-0.03c0.31-0.05,0.53-0.21,0.63-0.52c0.09-0.25,0.08-0.5,0.03-0.76
        		c-0.07-0.3-0.25-0.49-0.55-0.54C31.25,25.44,31.04,25.45,30.81,25.43z"/>
        	<path d="M5.34,26.45c0.01-0.38,0.06-0.69,0.2-0.98c0.2-0.39,0.51-0.63,0.96-0.69c0.44-0.05,0.84,0.04,1.16,0.37
        		c0.21,0.23,0.32,0.51,0.37,0.81c0.08,0.44,0.04,0.87-0.14,1.28c-0.27,0.6-0.89,0.85-1.49,0.72c-0.59-0.13-0.89-0.54-1.01-1.1
        		C5.37,26.71,5.36,26.54,5.34,26.45z M6.02,26.37c-0.01,0-0.02,0-0.03,0.01c0.05,0.2,0.07,0.4,0.14,0.59
        		c0.08,0.21,0.24,0.35,0.47,0.38c0.28,0.04,0.53-0.03,0.66-0.29c0.23-0.45,0.23-0.91-0.01-1.36c-0.2-0.38-0.78-0.36-1-0.09
        		C6.05,25.83,6.02,26.1,6.02,26.37z"/>
        	<path d="M26.29,26.39c-0.01,0.4-0.08,0.79-0.34,1.11c-0.37,0.47-0.95,0.61-1.52,0.4c-0.4-0.15-0.64-0.44-0.76-0.83
        		c-0.17-0.56-0.16-1.12,0.12-1.65c0.2-0.39,0.53-0.6,0.96-0.64c0.35-0.04,0.69,0.02,0.98,0.24c0.32,0.25,0.47,0.59,0.53,0.98
        		C26.27,26.13,26.27,26.26,26.29,26.39z M24.21,26.38c0.02,0.15,0.03,0.31,0.07,0.45c0.09,0.32,0.26,0.47,0.49,0.51
        		c0.31,0.05,0.58-0.03,0.71-0.34c0.2-0.45,0.2-0.91-0.05-1.33c-0.23-0.39-0.84-0.34-1.06,0.03C24.25,25.91,24.22,26.14,24.21,26.38z
        		"/>
        	<path d="M2.87,27.04c0.19,0.1,0.36,0.23,0.55,0.28c0.17,0.05,0.37,0.04,0.55,0.02c0.16-0.01,0.25-0.13,0.26-0.26
        		c0.01-0.18-0.05-0.27-0.24-0.33c-0.23-0.07-0.47-0.14-0.7-0.2c-0.42-0.1-0.69-0.37-0.71-0.73c-0.03-0.48,0.18-0.83,0.62-0.97
        		c0.61-0.19,1.16-0.06,1.64,0.41c-0.15,0.14-0.29,0.29-0.44,0.42c-0.01,0.01-0.06-0.01-0.08-0.03c-0.22-0.19-0.46-0.29-0.75-0.26
        		c-0.2,0.02-0.33,0.12-0.33,0.27c0,0.12,0.06,0.19,0.17,0.22c0.24,0.08,0.49,0.14,0.74,0.21c0.73,0.2,0.9,0.79,0.65,1.37
        		c-0.14,0.32-0.45,0.45-0.78,0.5c-0.44,0.07-0.86,0.01-1.24-0.23c-0.01-0.01-0.02-0.01-0.03-0.02c-0.09-0.09-0.22-0.16-0.26-0.26
        		c-0.05-0.14,0.14-0.17,0.21-0.26C2.75,27.13,2.83,27.08,2.87,27.04z"/>
        	<path d="M8.67,24.82c0.19,0,0.37,0,0.54,0c0.07,0,0.1,0.01,0.1,0.09c0,0.52-0.01,1.04-0.01,1.56c0,0.21,0.01,0.41,0.12,0.6
        		c0.21,0.37,0.74,0.4,0.98,0.05c0.09-0.13,0.14-0.28,0.14-0.44c0-0.58,0.01-1.17,0-1.75c0-0.08,0.01-0.12,0.11-0.12
        		c0.15,0.01,0.31,0,0.46,0c0.03,0,0.07,0.05,0.07,0.07c0,0.62,0.02,1.24-0.01,1.86c-0.02,0.46-0.19,0.88-0.63,1.1
        		c-0.67,0.33-1.64,0.11-1.83-0.8c-0.04-0.19-0.06-0.38-0.06-0.57c-0.01-0.51,0-1.02,0-1.54C8.66,24.91,8.67,24.87,8.67,24.82z"/>
        	<path d="M28.77,24.82c0.21,0,0.41,0,0.62,0c0,0.02,0.01,0.04,0.01,0.06c0,0.63,0.01,1.26-0.01,1.89c-0.02,0.47-0.19,0.88-0.64,1.1
        		c-0.53,0.26-1.3,0.18-1.67-0.41c-0.13-0.2-0.2-0.44-0.2-0.67c-0.01-0.65,0-1.3,0-1.97c0.2,0,0.39,0,0.58,0
        		c0.02,0,0.06,0.06,0.06,0.08c0.01,0.6,0,1.21,0.01,1.81c0,0.22,0.09,0.42,0.28,0.56c0.34,0.24,0.85,0.04,0.91-0.37
        		c0.05-0.38,0.04-0.76,0.05-1.14C28.78,25.45,28.77,25.14,28.77,24.82z"/>
        	<path d="M2.65,19.49c-0.03,0.24-0.06,0.48-0.09,0.73c-0.05,0.36-0.09,0.72-0.14,1.07c-0.05,0.37-0.1,0.75-0.15,1.12
        		C2.26,22.5,2.24,22.6,2.13,22.6c-0.12,0-0.15-0.08-0.16-0.18c-0.04-0.34-0.08-0.69-0.12-1.03c-0.04-0.35-0.08-0.69-0.12-1.04
        		c-0.06-0.52-0.11-1.04-0.02-1.56c0.09-0.51,0.11-1.03,0.16-1.55c0.03-0.26,0.05-0.51,0.09-0.77c0.01-0.09,0.04-0.19,0.17-0.18
        		c0.11,0,0.14,0.09,0.15,0.18c0.06,0.47,0.11,0.94,0.17,1.41c0.06,0.5,0.13,1,0.19,1.5C2.65,19.42,2.65,19.45,2.65,19.49z"/>
        	<path d="M20.88,27.28c-0.19,0.39-0.49,0.62-0.91,0.7c-0.63,0.11-1.32-0.17-1.54-0.95c-0.17-0.59-0.15-1.17,0.21-1.69
        		c0.24-0.36,0.58-0.53,1-0.56c0.4-0.02,0.75,0.07,1.02,0.37c0.08,0.09,0.15,0.2,0.23,0.32c-0.2,0.09-0.37,0.18-0.55,0.25
        		c-0.02,0.01-0.08-0.02-0.09-0.04c-0.26-0.37-0.81-0.36-1.07,0.01c-0.25,0.37-0.23,1.07,0.02,1.41c0.25,0.33,0.8,0.37,1.04-0.01
        		c0.07-0.1,0.12-0.12,0.22-0.05C20.6,27.12,20.74,27.19,20.88,27.28z"/>
        	<path d="M21.4,27.95c0-0.08,0-0.14,0-0.19c0-0.94,0-1.87,0-2.81c0-0.11,0.02-0.15,0.14-0.13c0.12,0.02,0.25,0.02,0.37,0
        		c0.11-0.02,0.14,0.02,0.14,0.12c0,0.57,0,1.15,0,1.72c0,0.21,0,0.42,0,0.65c0.42,0,0.83,0,1.25,0c0,0.2,0,0.38,0,0.56
        		c0,0.08-0.06,0.07-0.12,0.07c-0.45,0-0.91,0-1.36,0C21.68,27.95,21.55,27.95,21.4,27.95z"/>
        	<path d="M1.3,19.51c-0.03,0.22-0.07,0.47-0.11,0.72c-0.06,0.36-0.11,0.72-0.17,1.08c-0.01,0.09-0.05,0.17-0.16,0.16
        		c-0.1-0.01-0.14-0.07-0.14-0.17c0-0.08-0.03-0.15-0.04-0.23c-0.05-0.39-0.09-0.78-0.16-1.17c-0.06-0.36-0.01-0.71,0.04-1.06
        		c0.04-0.29,0.08-0.58,0.12-0.88c0.02-0.13,0.02-0.26,0.06-0.38c0.01-0.05,0.08-0.11,0.13-0.12C0.94,17.45,1,17.5,1.02,17.59
        		c0.05,0.34,0.1,0.67,0.15,1.01c0.04,0.25,0.08,0.51,0.12,0.76C1.3,19.4,1.3,19.45,1.3,19.51z"/>
        </g>
        </svg>
</a>
        <a href='https://www.instagram.com/whoisourkid'>

        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        	 viewBox="0 0 35 35" >
        <g>
        	<path d="M17.57,7.85c3.25,0,3.63,0.01,4.92,0.07c3.3,0.15,4.84,1.71,4.99,4.99c0.06,1.28,0.07,1.67,0.07,4.92
        		c0,3.25-0.01,3.63-0.07,4.92c-0.15,3.27-1.69,4.84-4.99,4.99c-1.28,0.06-1.67,0.07-4.92,0.07c-3.25,0-3.63-0.01-4.92-0.07
        		c-3.31-0.15-4.84-1.72-4.99-4.99c-0.06-1.28-0.07-1.67-0.07-4.92c0-3.25,0.01-3.63,0.07-4.92c0.15-3.27,1.69-4.84,4.99-4.99
        		C13.94,7.87,14.32,7.85,17.57,7.85z M17.57,5.66c-3.31,0-3.72,0.01-5.02,0.07c-4.42,0.2-6.88,2.66-7.08,7.08
        		c-0.06,1.3-0.07,1.71-0.07,5.02s0.01,3.72,0.07,5.02c0.2,4.42,2.66,6.88,7.08,7.08c1.3,0.06,1.71,0.07,5.02,0.07
        		s3.72-0.01,5.02-0.07c4.42-0.2,6.88-2.66,7.08-7.08c0.06-1.3,0.07-1.71,0.07-5.02s-0.01-3.72-0.07-5.02
        		c-0.2-4.42-2.65-6.88-7.08-7.08C21.29,5.67,20.87,5.66,17.57,5.66z M17.57,11.58c-3.45,0-6.25,2.8-6.25,6.25s2.8,6.25,6.25,6.25
        		s6.25-2.8,6.25-6.25C23.82,14.38,21.02,11.58,17.57,11.58z M17.57,21.89c-2.24,0-4.06-1.82-4.06-4.06c0-2.24,1.82-4.06,4.06-4.06
        		s4.06,1.82,4.06,4.06C21.63,20.07,19.81,21.89,17.57,21.89z M24.07,9.87c-0.81,0-1.46,0.65-1.46,1.46c0,0.81,0.65,1.46,1.46,1.46
        		c0.81,0,1.46-0.65,1.46-1.46C25.53,10.53,24.87,9.87,24.07,9.87z"/>
        </g>
        </svg>
</a>
      </Socials>
    )
  }
}
