/**
 * Entry point for the app.
 * Each file in the pages diretory is a route in the app.
 * e.g index.js = '/',  http://localhost:3000/,
 * about.js = http://localhost:3000/about/
 */
import React from 'react'
import styled from 'styled-components'
import OurKid from './OK-outline.svg'
import ReactVivus from 'react-vivus'
import Logos from './components/Logos'
import Youtube from './components/Youtube'
import Header from './components/Header'
import Marqueee from './components/Marqueee'
import Twitch from './components/Twitch'
import RotatingLogoSmallComp from './components/RotatingLogoSmall'
import eye from './eye.svg'
import './App.css'

const LogoSpace = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  margin: 30px 0;
  justify-content: center;
  position: relative;
  z-index: 1500;

  @media only screen and (max-width: 678px) {
    padding: 0 5%;
  }
`

const Relative = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
`

const MobileWarning = styled.div`
  background: black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999;
  text-align: center;
  overflow: hidden;
  color: white;
  h4 {
    color: white;
  }
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`

const Toggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 00px; */
  color: white;
  position: absolute;
  z-index: 999999999999999999999;
  left: 45%;
  bottom: 5%;
  font-size: 16.66px;
  color: white;
  padding: 10.666px 26.666px;
  cursor: pointer;
  line-height: normal;
  opacity: 0.666;
  border: 1.5px solid white;

  img {
    max-height: 40px;
    border-color: white;
  }
  p {
    margin: 0;
  }
  &:hover {
    background: white;
    color: black;
    opactiy: 1;
  }
`

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: false,
      showWarning: false,
    }
  }

  toggle = () => {
    this.setState({ showVideo: !this.state.showVideo });
  }

  closeWarning = () => {
    this.setState({ showWarning: false });
  }

  render() {
    const text = this.state.showVideo ? 'HIDE' : 'SHOW'
    return (
      <Relative>
        <RotatingLogoSmallComp />
        <Header />
        <Youtube />
        {/* <RotatingLogo /> */}
        <LogoSpace>
          <ReactVivus
            id='foo'
            option={{
              file: OurKid,
              animTimingFunction: 'EASE',
              type: 'sync',
              onReady: console.log
            }}
            style={{ height: '166.66px', width: '333px', fill: 'none !important', stroke: 'blue' }}
            callback={console.log}
          />
        </LogoSpace>
        <Logos />
        {this.state.showVideo && (
          <Twitch />
        )}
        <Marqueee />
        <MobileWarning
          onClick={this.closeWarning}
          style={{
            display: (this.state.showWarning) ? 'block' : 'none'
          }}
        >
          <h4>This  website eats data 🥵 and as such is best enjoyed on Wifi 🧩, Desktop 🛴 or the Twitch App 💫 if mobile inclined</h4>
        </MobileWarning>
      </Relative>
    )
  }


}

export default App
