import React from 'react'
import styled from 'styled-components'
import Our from '../../our.svg'
import Kid from '../../kid.svg'

const Marquee = styled.marquee`
  height: 106.6px;
  position: absolute;
  bottom: -2%;
  width: 100%;
  z-index: 2;

    img {
      height: 100%;
    }
`

export default class Marqueee extends React.Component {
  // Random () {
  //   var number = Math.floor((Math.random() * 666) + 1)
  //   console.log('number>>', number)
  //   return nu  mber
  // }
  constructor () {
    super()
    this.state = { classes: 'hidden' }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ classes: 'visible' }), 300);
  }
  render () {
   const { classes } = this.state;
    return (
      <Marquee className={classes} >
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />
      <img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} /><img src={Our} /><img src={Kid} />

      </Marquee>
    )
  }
}
